@import '../../../scss/variables.scss';

header.header {
  //position: sticky !important;
  z-index: 2;

  top: 0;
  left: 0;
  right: 0;

  background-color: transparent !important;
  box-shadow: none !important;
}

button.authBtn {
  position: relative;
  margin-left: 1.5rem;
  color: #000;
  background: $authBtnColor;
  border: 1px solid $authBtnColor;
  box-shadow: rgba(0, 0, 0, 0.1) 0 1px 3px 0, rgba(0, 0, 0, 0.06) 0 1px 2px 0;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;

  &:hover {
    background: transparent;
    border-color: #000;
  }
}

button.loginBtn {
  padding: 5px 35px 5px 25px;
}

button.logoutBtn {
  padding: 5px 25px 5px 35px;
}

.loginIcon {
  position: absolute;
  right: 5px;
  height: 100%;
}

.logoutIcon {
  position: absolute;
  left: 5px;
  height: 100%;
  transform: rotate(180deg);
}
