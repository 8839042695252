button.MuiButton-root {
  border-radius: 24px;
}

div.MuiAccordion-root {
  border-radius: 1.5rem;
  margin-bottom: 1.5rem;
  //background: rgba(243, 245, 250, 0.72);
  background: #fcfcfd;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);

  &:before {
    display: none;
  }

  &:first-of-type {
    border-radius: 1.5rem;
  }

  &:last-of-type {
    border-radius: 1.5rem;
  }
}

div.MuiAccordion-root.Mui-expanded {
  &:last-of-type {
    margin-bottom: 1.5rem;
  }
}

div.MuiAlert-root {
  border-radius: 1.5rem;
}

div.MuiAccordionSummary-content {
  font-weight: 500;
  font-size: 1.2rem;
  margin: 20px 5px;
}

div.MuiAccordionDetails-root {
  padding: 30px 50px;
}
