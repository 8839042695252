@import "fonts";
@import "muiComponents";

* {
  box-sizing: border-box;
}

html, #root {
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
  background-color: #f4f6f9;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Raleway', sans-serif;
}

//global
.d-none {
  display: none !important;
}

a {
  text-decoration: none !important;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type="number"] {
  -moz-appearance: textfield;
}

//temp
.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

//lender credit policy

.css-1n4h3ua-MuiButtonBase-root-MuiToggleButton-root.Mui-selected {
  background-color: #6683ea !important;
  z-index: 1;
}

#superset-widget iframe {
  width: 100%;
  height: 100vh;
}

// Preview docx wrapper
.docx-wrapper {
  background: none !important;
  padding: 0 !important;
}

.MuiDataGrid-viewport,
.MuiDataGrid-row,
.MuiDataGrid-renderingZone {
  max-height: fit-content !important;
}

.MuiDataGrid-cell {
  max-height: fit-content !important;
  overflow: auto;
  white-space: initial !important;
  display: flex !important;
  align-items: center;
}
